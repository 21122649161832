<template>
  <component :title="dashboardTitle" :is="component"></component>
</template>

<script>
import { mapGetters } from 'vuex';
import PersonaConstants from '@/shared/constants/PersonaConstants';

export default {
  name: 'DashboardManager',

  components: {
    BuyerDashboard: () => import('./BuyerDashboard'),
    VendorDashboard: () => import('./VendorDashboard'),
    SuperDashboard: () => import('./SuperDashboard'),
  },

  computed: {
    ...mapGetters('auth', ['user']),
    component() {
      const { persona } = this.user || '';
      switch (persona) {
        case PersonaConstants.super.value:
          return 'SuperDashboard';
        case PersonaConstants.vendor.value:
          return 'VendorDashboard';
        case PersonaConstants.buyer.value:
          return 'BuyerDashboard';
        default:
          return 'BuyerDashboard';
      }
    },

    userTypeTitle() {
      if (this.user === null) {
        return 'User';
      }
  
      let groups = this.user.groups;
      let title = 'User';
      if (groups === null || groups.length === 0) {
        return null;
      }

      title = this.highestType(groups);

      const shouldAppendSuffix = ['vendor', 'buyer'].includes(
        title.toLowerCase()
      );

      if (shouldAppendSuffix) {
        title += ' User';
      }

      return title;
    },
    dashboardTitle() {
      if (this.userTypeTitle === null && this.user.persona === null) {
        return 'User Dashboard';
      }

      if (this.userTypeTitle !== null) {
        return `${this.userTypeTitle} Dashboard`;
      }

      return `${this.user.persona} User Dashboard`;
    },
  },

  methods: {
    highestType(groups) {
      const groupNames = [
        'owner',
        'administrator',
        'vendor',
        'buyer',
        'estimator',
        'superintendent',
        'laborer',
      ];

      //Sorts the groups by index value compared to the above array
      const groupValues = groups
        .map((group) => {
          return groupNames.indexOf(group.name.toLowerCase());
        })
        .sort((a, b) => a - b);

      const highestTypeIndex = groupValues.find((a) => a > -1);
      const secondHighestTypeIndex = groupValues.find(
        (a) => a > -1 && a !== highestTypeIndex
      );

      if (groupValues.length > 0 && highestTypeIndex !== undefined) {
        const firstHighestType = groupNames[highestTypeIndex];
        const secondHighestType = groupNames[secondHighestTypeIndex];

        if (secondHighestType) {
          //Example: Administrator Vendor
          return `${firstHighestType} ${secondHighestType}`;
        }

        /**
         * Exceptional Case:
         * If 'owner' then prefix their persona
         * Example: Buyer Owner
         */
        if (firstHighestType === 'owner') {
          return `${this.user.persona} ${firstHighestType}`;
        }

        //Example: Administrator
        return `${firstHighestType}`;
      }

      return 'User';
    },
  },
};
</script>
